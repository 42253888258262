import React from "react";
import Image from "images/stay-home-es.png";

import s from "./info.module.scss";

const Info = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div className="horizontalInverted" />
          <div className="verticalInverted" />
          <div className="bottomHorizontalInverted" />
          <div className="bottomVerticalInverted" />
          <div className={s.textContainer}>
            <h1
              className={s.headline}
              dangerouslySetInnerHTML={{
                __html: "Nuestra lucha contra el COVID-19"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "La lucha contra el brote de COVID-19 está afectando nuestra forma de vivir en nuestra comunidad, condado, estado, país y mundo.  Requiere que demos un paso adelante para ayudar a nuestra familia, parientes, vecinos, cerca o distantes de nosotros. Esta lucha probablemente será prolongada, ya que diferentes oleadas del brote pueden afectar nuestra salud y bienestar.  Por esta razón, estamos firmemente convencidos de que esta es una oportunidad única para enfrentar los desafíos sin precedentes que nos esperan, cuidándonos de una manera más natural, saludable y sostenible."
              }}
            />
          </div>
          <div className={s.imageContainer}>
            <img className={s.image} src={Image} alt="The Earth" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
