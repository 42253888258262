import React from "react";
import Ribbon from "images/ribbon.png";

import s from "./ribbon.module.scss";

const Rainbow = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div className="horizontal" />
          <div className="vertical" />
          <div className="bottomHorizontal" />
          <div className="bottomVertical" />
          <div className={s.ribbonContainer}>
            <h1
              className={s.title}
              dangerouslySetInnerHTML={{
                __html: "Download Our Ribbon"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "If you want to join us in our <i><strong>STAY SAFE & BE WISE</strong></i> journey, please detach the ribbon below and feel free to make good use of our creativity and natural approach of solidarity to  display it on the site of your preference as a symbol of closeness with those somehow affected by the COVID-19 outbreak. This is another opportunity to show our respect to the brave health care and food providers around the world, who with enormous personal sacrifice are giving their best for others. Let’s make it a message of hope for those striving for their day to day wellbeing and thus making the world more in tune with nature."
              }}
            />
            <img className={s.ribbon} src={Ribbon} alt="Safe and Wise Ribbon" />
            <a href={Ribbon} download="Safe & Wise Ribbon" className={s.button}>
              DOWNLOAD!
            </a>
            <p
              className={s.center}
              dangerouslySetInnerHTML={{
                __html:
                  "We care about you, your nutrition, health and wellbeing, so please<br><br><i><strong> STAY SAFE & BE WISE!</strong></i>"
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rainbow;
