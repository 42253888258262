import React from "react";
import Logo from "images/logo-small.png";
import Facebook from "images/facebook.png";
import Twitter from "images/twitter.png";
import Instagram from "images/instagram.png";
import English from "images/english.png";
import Spanish from "images/spanish.png";

import s from "./logo-section.module.scss";

const Header = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div className={s.topBar}>
            <div className={s.social}>
              <a href={"https://www.facebook.com/"}>
                <img className={s.icon} src={Facebook} alt="Facebook Icon" />
              </a>
              <a href={"https://twitter.com/SafeandWise"}>
                <img className={s.icon} src={Twitter} alt="Twitter Icon" />
              </a>
              <a href={"https://www.instagram.com/"}>
                <img className={s.icon} src={Instagram} alt="Instagram Icon" />
              </a>
            </div>
            <div className={s.language}>
              <a href="/">
                <img className={s.flag} src={English} alt="USA Flag" />
              </a>
              <a href={"/es"}>
                <img className={s.flag} src={Spanish} alt="Spain Flag" />
              </a>
            </div>
          </div>
          <div className={s.logoContainer}>
            <a className={s.link} href="/">
              <img className={s.logo} src={Logo} alt="Safe & Wise logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
