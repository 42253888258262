import React from "react";
import Image from "images/mail.png";

import s from "./contact.module.scss";

const Contact = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div id={s.horizontal} className="horizontal" />
          <div className="vertical" />
          <div className="bottomHorizontal" />
          <div className="bottomVertical" />
          <div className={s.textContainer}>
            <h1
              className={s.headline}
              dangerouslySetInnerHTML={{
                __html: "Contáctanos"
              }}
            />
            <p className={s.text}>
              Si requieres contactarnos, por favor escríbenos a <strong><a href="mailto:info@safeandwise.com">info@safeandwise.com</a></strong>
            </p>
            <p
              className={s.center}
              dangerouslySetInnerHTML={{
                __html:
                  "¡Te responderemos lo antes posible!"
              }}
            />
          </div>
          <div className={s.imageContainer}>
            <img className={s.image} src={Image} alt="Social Distancing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
