import React from "react";
import Image from 'images/distance.png'

import s from "./join.module.scss";

const Join = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div id={s.horizontal} className="horizontal" />
          <div className="vertical" />
          <div className="bottomHorizontal" />
          <div className="bottomVertical" />
          <div className={s.textContainer}>
            <h1
              className={s.headline}
              dangerouslySetInnerHTML={{
                __html: "Join The Safe & Wise Journey"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "Prompted by a common spirit of solidarity, we at <i><strong>Safe & Wise Inc.</strong></i> are cooperating by spreading the word about the importance of maintaining social distancing to preserve our and the wellbeing of others. "
              }}
            />
            <p
              className={s.center}
              dangerouslySetInnerHTML={{
                __html:
                  "This is certainly a time to <i><strong>STAY SAFE & BE WISE!</strong></i>"
              }}
            />
          </div>
          <div className={s.imageContainer}>
            <img className={s.image} src={Image} alt="Social Distancing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Join;
