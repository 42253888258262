import React from "react";
import Header from "components/header";
import HeroEs from "components/hero-es";
import InfoEs from "components/info-es";
import TextEs from "components/only-text-es";
import JoinEs from "components/join-es";
import RibbonEs from "components/ribbon-es";
import ContactEs from "components/contact-es";
import FooterEs from "components/footer-es";

const HomeEs = () => {
  return (
    <>
      <Header />
      <HeroEs />
      <InfoEs />
      <TextEs />
      <JoinEs />
      <RibbonEs />
      <ContactEs />
      <FooterEs />
    </>
  );
};

export default HomeEs;
