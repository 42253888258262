function isInViewPort(el) {
  let elementBoundingData = el.getBoundingClientRect();
  return (
    elementBoundingData.top >= 0 &&
    elementBoundingData.bottom <=
      (window.innerHeight || document.documentElement.clientHeight)
  );
}

export default isInViewPort;
