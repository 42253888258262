import React from "react";
import Image from 'images/distance.png'

import s from "./join.module.scss";

const Join = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div id={s.horizontal} className="horizontal" />
          <div className="vertical" />
          <div className="bottomHorizontal" />
          <div className="bottomVertical" />
          <div className={s.textContainer}>
            <h1
              className={s.headline}
              dangerouslySetInnerHTML={{
                __html: "Únete a la jornada Safe & Wise"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "Impulsados por un espíritu común de solidaridad, en  <i><strong>Safe & Wise Inc.</strong></i> estamos cooperando al difundir la importancia de mantener el distanciamiento social para preservar nuestro bienestar y el de los demás."
              }}
            />
            <p
              className={s.center}
              dangerouslySetInnerHTML={{
                __html:
                  "¡Este es sin duda un momento para <i><strong>permanecer seguro y ser sabio!</strong></i>"
              }}
            />
          </div>
          <div className={s.imageContainer}>
            <img className={s.image} src={Image} alt="Social Distancing" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Join;
