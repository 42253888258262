import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './index.css';
import 'styles/styles.scss';
//Components
import Home from 'pages/home';
import HomeEs from 'pages/home-es';

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/es" component={HomeEs} />
    </Switch>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));
