import React from "react";
import Ribbon from "images/ribbon.png";

import s from "./ribbon.module.scss";

const Rainbow = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div className="horizontal" />
          <div className="vertical" />
          <div className="bottomHorizontal" />
          <div className="bottomVertical" />
          <div className={s.ribbonContainer}>
            <h1
              className={s.title}
              dangerouslySetInnerHTML={{
                __html: "Descarga nuestra cinta"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "Si quieres unirte a nuestra jornada <i><strong>STAY SAFE & BE WISE</strong></i>, descarga la cinta de abajo y siéntete libre de hacer un buen uso de nuestra creatividad y enfoque natural de solidaridad para mostrarlo en el sitio de tu preferencia como símbolo de cercanía con los que de alguna manera se ven afectados por el brote del COVID-19. Esta es una oportunidad para mostrar nuestro respeto a los valientes proveedores de atención médica y alimentos de todo el mundo, que con enorme sacrificio personal están dando lo mejor de sí mismos a los demás. Hagamos que sea un mensaje de esperanza para aquellos que se esfuerzan por su bienestar diario y así hacer que el mundo esté más en sintonía con la naturaleza."
              }}
            />
            <img className={s.ribbon} src={Ribbon} alt="Safe and Wise Ribbon" />
            <a href={Ribbon} download="Safe & Wise Ribbon" className={s.button}>
              DESCARGAR!
            </a>
            <p
              className={s.center}
              dangerouslySetInnerHTML={{
                __html:
                  "Nos preocupamos por ti, tu nutrición, salud y bienestar, así que por favor<br><br><i><strong>¡PERMANECE SEGURO Y SÉ SABIO!</strong></i>"
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Rainbow;
