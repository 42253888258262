import React, { useState, useEffect, useRef } from "react";
import viewPortDetect from 'utils/viewPortDetect';

import s from "./text.module.scss";

const Text = () => {
  const [isInViewport, setIsInViewPort] = useState(false);

  const bannerRef = useRef(null);

  useEffect(() => {
    viewPortDetect(bannerRef.current) ? setIsInViewPort(true) : setIsInViewPort(false);
    window.addEventListener('scroll', () => {
      viewPortDetect(bannerRef.current) ? setIsInViewPort(true) : setIsInViewPort(false);
    });
  }, []);

  return (
    <section ref={bannerRef} className={`${isInViewport ? s.section : s.notVisible}`}>
      <div className="container">
        <div className={s.wrapper}>
          <div className={s.textContainer}>
            <h1
              className="white"
              dangerouslySetInnerHTML={{
                __html: "Permanece seguro y sé sabio"
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Text;
