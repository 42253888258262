import React, { useEffect, useRef } from "react";
import Image from "images/logo.png";
import viewPortDetect from 'utils/viewPortDetect';

import s from './sticky.module.scss';

const StickyBanner = ({ isInViewport, setIsInViewPort }) => {

  const bannerRef = useRef(null);

  useEffect(() => {
    viewPortDetect(bannerRef.current) ? setIsInViewPort(true) : setIsInViewPort(false);
    window.addEventListener('scroll', () => {
      viewPortDetect(bannerRef.current) ? setIsInViewPort(true) : setIsInViewPort(false);
    });
  }, []);

  return (
    <>
      <img ref={bannerRef} className={`${isInViewport ? s.image : s.displayNone}`} src={Image} alt="A Network of People" />
    </>
  );
}

export default StickyBanner;
