import React from "react";
import Image from "images/stay-home.png";

import s from "./info.module.scss";

const Info = () => {
  return (
    <section className={s.section}>
      <div className="container">
        <div className={s.wrapper}>
          <div className="horizontalInverted" />
          <div className="verticalInverted" />
          <div className="bottomHorizontalInverted" />
          <div className="bottomVerticalInverted" />
          <div className={s.textContainer}>
            <h1
              className={s.headline}
              dangerouslySetInnerHTML={{
                __html: "Our Fight Against COVID-19"
              }}
            />
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html:
                  "The fight against the COVID-19 outbreak is now affecting our way of living in our community, county, state, country and the world, indeed.  It requires us to take one step forward to help our family, relatives, neighbors – close of far away from us. This fight will most likely be a prolonged one as different waves of its outbreak may impair our health and wellbeing.  For that reason, we are firmly convinced this is a one in a lifetime opportunity to raise to the unprecedented challenges that lie ahead of us by taking care of ourselves in a more natural, healthier and sustainable manner."
              }}
            />
          </div>
          <div className={s.imageContainer}>
            <img className={s.image} src={Image} alt="The Earth" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
