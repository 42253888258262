import React, { useState } from "react";
import Logo from "components/logo-section";
import StickyBanner from 'components/shared/stickyBanner';

import s from "./hero.module.scss";

const Hero = () => {
  const [isInViewport, setIsInViewPort] = useState(false);

  return (
    <>
      <section className={s.section}>
        <div className="container">
          <div className={s.wrapper}>
            <div className="horizontal" />
            <div className="vertical" />
            <div className="bottomHorizontal" />
            <div className="bottomVertical" />
            <div className={s.textWrapper}>
              <h1
                className={s.headline}
                dangerouslySetInnerHTML={{
                  __html: "Estamos aquí para tu bienestar"
                }}
              />
              <p
                className={s.text}
                dangerouslySetInnerHTML={{
                  __html:
                    "Somos una empresa emergente que, mientras proporciona oportunidades de bienestar para todos, se centra en el desarrollo de alternativas nutricionales para proporcionarte productos alimenticios y bebidas a base de plantas que podrían proteger tu sistema inmunológico a precios razonables en una presentación sostenible. ¡Estamos aquí para proporcionarte una atención más saludable, nutriendo tu cuerpo y tranquilidad mental, y así mejorar tu bienestar!"
                }}
              />
              <p
                className={s.text}
                dangerouslySetInnerHTML={{
                  __html:
                    "Estamos trabajando duro para que nuestros productos estén disponibles muy pronto en tu comunidad, que será el ambiente central de nuestro emprendimiento, ya que es el epicentro de tu día a día. No obstante, tu comunidad necesita ahora la ayuda de cualquier persona que pueda echar una mano por una causa común."
                }}
              />
            </div>
            <div className={s.imageWrapper}>
              <StickyBanner isInViewport={isInViewport} setIsInViewPort={setIsInViewPort} />

              {/* <p
              className="small"
              dangerouslySetInnerHTML={{
                __html: "Remember to stay safe and be wise!"
              }}
            /> */}
            </div>
          </div>
        </div>
      </section>
      {
        !isInViewport
          ?
          <Logo />
          :
          ''
      }
    </>
  );
};

export default Hero;
