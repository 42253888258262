import React from "react";


import s from "./footer.module.scss";

const Footer = () => {
  return (
    <footer>
      <p className={s.footerText}>Todos los derechos reservados para <i><strong>Safe & Wise Inc.</strong></i></p>
    </footer>
  );
};

export default Footer;
