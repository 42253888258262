import React, { useState } from "react";
import Logo from "components/logo-section";
import StickyBanner from 'components/shared/stickyBanner';

import s from "./hero.module.scss";

const Hero = () => {
  const [isInViewport, setIsInViewPort] = useState(false);

  return (
    <>
      <section className={s.section}>
        <div className="container">
          <div className={s.wrapper}>
            <div className="horizontal" />
            <div className="vertical" />
            <div className="bottomHorizontal" />
            <div className="bottomVertical" />
            <div className={s.textWrapper}>
              <h1
                className={s.headline}
                dangerouslySetInnerHTML={{
                  __html: "We Are Here For Your Wellbeing"
                }}
              />
              <p
                className={s.text}
                dangerouslySetInnerHTML={{
                  __html:
                    "We are a startup enterprise that, while providing wellbeing opportunities for everyone, is focused on the development of nutritional alternatives to provide you with plant-based food products and beverages that could boost your immune system at reasonable prices in a sustainable presentation. We are here to provide you with a healthier approach to nurture your body and peace of mind, and thereby enhance your wellbeing!"
                }}
              />
              <p
                className={s.text}
                dangerouslySetInnerHTML={{
                  __html:
                    "We are working hard to make our products available very soon in your community, which will be the center court of our entrepreneurship, as it is the epicenter of your day to day living. Notwithstanding, your community needs now the help of anyone who may lend a hand for a common cause."
                }}
              />
            </div>
            <div className={s.imageWrapper}>
              <StickyBanner isInViewport={isInViewport} setIsInViewPort={setIsInViewPort} />

              {/* <p
              className="small"
              dangerouslySetInnerHTML={{
                __html: "Remember to stay safe and be wise!"
              }}
            /> */}
            </div>
          </div>
        </div>
      </section>
      {
        !isInViewport
          ?
          <Logo />
          :
          ''
      }
    </>
  );
};

export default Hero;
