import React from "react";
import Header from "components/header";
import Hero from "components/hero";
import Info from "components/info";
import Text from "components/only-text";
import Join from "components/join";
import Ribbon from "components/ribbon";
import Contact from "components/contact";
import Footer from "components/footer";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Info />
      <Text />
      <Join />
      <Ribbon />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
